const EmbedUtility = (($) => {
    class EmbedUtility {
        constructor(node) {
            this.node = node;
            this.$node = $(node);

            // Ensure YouTube videos have enablejsapi=1
            this.ensureYouTubeAPI();
        }

        ensureYouTubeAPI() {
            if (this.node.tagName === "IFRAME" && this.node.src.includes("youtube.com")) {
                if (!this.node.src.includes("enablejsapi=1")) {
                    let newSrc = this.node.src.includes("?") 
                        ? this.node.src + "&enablejsapi=1" 
                        : this.node.src + "?enablejsapi=1";
                    this.node.src = newSrc;
                }
            }
        }

        pauseVideo() {
            if (this.node.tagName === "IFRAME") {
                let src = this.node.src;

                // ✅ Fix YouTube Videos: Ensure correct pause method
                if (src.includes("youtube.com") || src.includes("youtu.be")) {
                    this.node.contentWindow.postMessage(
                        JSON.stringify({ event: "command", func: "pauseVideo", args: "" }),
                        "*"
                    );
                }

                // ✅ Fix Vimeo Videos
                if (src.includes("vimeo.com") && window.Vimeo) {
                    let vimeoPlayer = new Vimeo.Player(this.node);
                    vimeoPlayer.pause();
                }
            }
        }

        static loadVimeoAPI() {
            if (!window.Vimeo) {
                let script = document.createElement("script");
                script.src = "https://player.vimeo.com/api/player.js";
                script.async = true;
                script.onload = () => console.log("✅ Vimeo API Loaded");
                document.head.appendChild(script);
            }
        }

        static initObserver() {
            if (!window.embedObserver) {
                window.embedObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (!entry.isIntersecting) {
                            let videoInstance = new EmbedUtility(entry.target);
                            videoInstance.pauseVideo();
                        }
                    });
                }, { threshold: 0.1 });
            }
        }

        observe() {
            EmbedUtility.initObserver();
            window.embedObserver.observe(this.node);
        }
    }

    return {
        init({ selector } = {}) {
            EmbedUtility.loadVimeoAPI(); // Ensure Vimeo API is loaded

            return jQuery(selector).map((index, node) => {
                const module = new EmbedUtility(node);
                module.observe();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    // Initialize EmbedUtility on page load
    EmbedUtility.init({
        selector: 'iframe[src*="youtube.com"], iframe[src*="youtu.be"], iframe[src*="vimeo.com"]',
    });

    // Listen for Slick slide changes and pause videos when slides change
    $(document).on("beforeChange", "[data-slick]", function () {
        $(this).find("iframe").each((index, node) => {
            EmbedUtility.init({ selector: node }); // ✅ Correct way to handle it
        });
    });

    // Reinitialize on AJAX updates
    $(document).on('ajaxComplete', () => {
        EmbedUtility.init({
            selector: 'iframe[src*="youtube.com"], iframe[src*="youtu.be"], iframe[src*="vimeo.com"]',
        });
    });
})(window.jQuery);
